interface ITabProps {
  title: string;
  defaultActive: boolean;
  children: JSX.Element;
}

function Tab({ children }: ITabProps) {
  return children;
}

Tab.defaultProps = {
  defaultActive: false,
  children: null,
};

export default Tab;
