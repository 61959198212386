/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  container: (provided: any) => ({
    ...provided,
    marginTop: '0vw',
    border: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    outline: 0,
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    borderBottom: '1px #fff solid',
    marginBottom: '4px',
    mixBlendMode: 'normal',
    border: '1px solid #5e91ff',
    borderRadius: '30px',
    background: '#ffffff',
    padding: '2px 12px',
    ':hover': {
      border: '1px solid #5e91ff',
      boxShadow: 'none',
    },
    '@media (max-width: 700px)': {
      minHeight: '38px',
    },
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    color: '#626262',
    cursor: 'pointer',
    fontSize: '0.875rem',
    ':hover': {
      backgroundColor: '#f2f2f2',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontWeight: 'normal',
    lineHeight: '100%',
    color: '#626262',
  }),
  input: (provided: any) => ({
    ...provided,
    outline: 0,
    fontWeight: 'normal',
    lineHeight: '100%',
    color: '#626262',
    width: '100%',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    outline: 0,
    fontWeight: 'normal',
    lineHeight: '100%',
    color: '#626262',
    '@media (max-width: 850px)': {
      marginRight: '20px',
    },
    textAlign: 'left',
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    borderRadius: '8px',
    padding: '8px 0',
    '@media (max-width: 850px)': {
      marginTop: '5px',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};
