import _ from 'lodash';
import React, { Component, RefObject } from 'react';
import classnames from 'classnames';
import AsyncSelect from 'react-select/async';

import Dispensary from './Dispensary';

import GoogleMap from './map/Map';
import Preloader from '../preloader/Preloader';
import NotFound from './notFound/NotFound';
import DispensariesFilter from './filter/DispensariesFilter';
import { IAddressOption } from 'components/shared/address-dropdown/AddressDropdown';

import Tabs from './tabs/Tabs';
import Tab from './tabs/Tab';

import { getDispensaries, getAutocomplete, getPlaceDetails } from 'services';
import { DispensariesFilterEnum } from 'services/enums';
import { getDistanceFromLatLonInKm, generateUUID } from 'services/helpers';

import styles from './DispensariesMap.module.scss';
import selectCustomStyle from './SelectCustomStyle';

export interface IDispensary {
  address: string;
  city: string;
  country: string;
  id: string;
  lat: string;
  lng: string;
  name: string;
  state: string;
  website: string;
  zip: string;
  area: string;
  logoUrl: string;
  createdAt: string;
  ref: RefObject<HTMLDivElement>;
}

interface IDispensariesMapProps {
  setRef?: RefObject<HTMLElement>;
  className?: string;
  classNameActive?: string;
  classes?: {
    root?: string;
    header?: string;
    headerLogo?: string;
    headerTitle?: string;
    headerSearch?: string;
    filterButton?: string;
  };
  lat?: string;
  lng?: string;
  onRedirect?(name: string): any;
}

interface IDispensariesMapState {
  query: string;
  dispensariesList: IDispensary[];
  activeDispensary: IDispensary | any;
  sortingRule(dispensaryA: IDispensary, dispensaryB: IDispensary): number;
  filteringRule(dispensary: IDispensary, index: number, array: IDispensary[]): boolean;
  dispensariesListFetching: boolean;
  dispensariesListFetchingFailed: boolean;
  selectAddress?: IAddressOption;
  lat: string;
  lng: string;
  filterName: DispensariesFilterEnum;
}

class DispensariesMap extends Component<IDispensariesMapProps, IDispensariesMapState> {
  inputRef = React.createRef<HTMLInputElement>();
  sessionId = generateUUID();

  private debouncedLoadOptions: any;

  constructor(props: IDispensariesMapProps) {
    super(props);

    this.state = {
      query: '',
      dispensariesList: [],
      activeDispensary: {},
      sortingRule: () => 0,
      filteringRule: () => true,
      dispensariesListFetching: false,
      dispensariesListFetchingFailed: false,
      selectAddress: undefined,
      lat: props.lat || '',
      lng: props.lng || '',
      filterName: DispensariesFilterEnum.all_stores,
    };

    this.setActiveDispensary = this.setActiveDispensary.bind(this);
    this.scrollToDispensary = this.scrollToDispensary.bind(this);
    this.chooseDispensary = this.chooseDispensary.bind(this);
    this.acceptFilter = this.acceptFilter.bind(this);
    this.getPlaceAutocomplete = this.getPlaceAutocomplete.bind(this);
    this.onSelectAddress = this.onSelectAddress.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.loadOptions = this.loadOptions.bind(this);

    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
  }

  componentDidMount() {
    this.getDispensaries(this.state.query, false);
  }

  componentDidUpdate(prevProps: IDispensariesMapProps, prevState: IDispensariesMapState) {
    if (
      (prevProps.lat !== this.state.lat || prevProps.lng !== this.state.lng) &&
      (prevProps.lat || prevProps.lng)
    ) {
      this.setState({ lat: prevProps.lat || '', lng: prevProps.lng || '' });
    }
    if (
      prevState.lat !== this.state.lat ||
      prevState.lng !==
        this.state.lng /*&&
      this.state.filterName === DispensariesFilterEnum.proximity*/
    ) {
      this.getDispensaries('');
    }
    if (
      prevState.filterName !== this.state.filterName &&
      (prevState.filterName === DispensariesFilterEnum.proximity ||
        this.state.filterName === DispensariesFilterEnum.proximity)
    ) {
      this.getDispensaries('');
    }
  }

  private isDispensaryActive(id: string): boolean {
    return Boolean(id === this.state.activeDispensary?.id);
  }

  private getDispensaries(query: string, dispensariesListFetching = true): DispensariesMap {
    this.setState({ dispensariesListFetching });

    let filter = '';
    if (this.state.filterName === DispensariesFilterEnum.proximity) {
      filter = `lat=${this.state.lat}&lng=${this.state.lng}&maxDistance=100`;
    } else {
      filter = `lat=${this.state.lat}&lng=${this.state.lng}`;
    }

    getDispensaries(query, filter)
      .then((response) => response.json())
      .then((resultData) => {
        if (!resultData?.length) {
          this.setState({
            dispensariesListFetching: false,
            dispensariesListFetchingFailed: true,
          });

          return;
        }

        this.setDispensariesList(
          resultData,
        ); /*.setActiveDispensary(
          resultData[0] ? resultData[0] : {},
        );*/
        this.setState({ dispensariesListFetching: false });
      });

    return this;
  }

  private setDispensariesList(dispensariesList: IDispensary[]): DispensariesMap {
    this.setState((state) => ({
      ...state,
      dispensariesList: dispensariesList.map((dispensary) => ({
        ...dispensary,
        ref: React.createRef<HTMLDivElement>(),
      })),
    }));

    return this;
  }

  private setActiveDispensary(activeDispensary: IDispensary): DispensariesMap {
    this.setState((state) => ({
      ...state,
      activeDispensary,
    }));

    return this;
  }

  private scrollToDispensary(id: string): DispensariesMap {
    const dispensary = this.state.dispensariesList.find((dispensary) => dispensary.id === id);

    if (dispensary?.ref?.current) {
      dispensary.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    return this;
  }

  private chooseDispensary(dispensary: IDispensary): DispensariesMap {
    this.setActiveDispensary(dispensary).scrollToDispensary(dispensary.id);
    return this;
  }

  private acceptFilter(filterName: DispensariesFilterEnum) {
    switch (filterName) {
      case DispensariesFilterEnum.proximity:
        const _lat = this.state.lat;
        const _lng = this.state.lng;
        this.setState({
          sortingRule(a: IDispensary, b: IDispensary) {
            const firstDistance = getDistanceFromLatLonInKm(+a.lat, +a.lng, +_lat, +_lng);
            const secondDistance = getDistanceFromLatLonInKm(+b.lat, +b.lng, +_lat, +_lng);
            return firstDistance < secondDistance ? 1 : firstDistance > secondDistance ? -1 : 0;
          },
          filteringRule: () => true,
        });
        break;
      case DispensariesFilterEnum.all_stores:
        this.setState({ sortingRule: () => 0, filteringRule: () => true });
        break;
      case DispensariesFilterEnum.southern_california:
        this.setState({
          sortingRule: () => 0,
          filteringRule(dispensary: IDispensary) {
            return (dispensary.area || '').toLowerCase() === 'southern california';
          },
        });
        break;
      case DispensariesFilterEnum.northern_california:
        this.setState({
          sortingRule: () => 0,
          filteringRule(dispensary: IDispensary) {
            return (dispensary.area || '').toLowerCase() === 'northern california';
          },
        });
        break;
      default:
        this.setState({ sortingRule: () => 0, filteringRule: () => true });
        break;
    }
  }

  private closeNotFoundMessage() {
    this.setState({ dispensariesListFetchingFailed: false });
  }

  private clearLocation() {
    this.setState({ lat: '', lng: '' });
  }

  private onChangeFilter(filterName: DispensariesFilterEnum) {
    this.setState({ filterName: filterName }, () => this.acceptFilter(filterName));
  }

  private onSelectAddress(address: IAddressOption) {
    this.setState({ selectAddress: address });
    if (address) {
      getPlaceDetails(address.placeId, this.sessionId)
        .then((resultData: any) => {
          if (resultData.location) {
            this.setState({ lat: resultData.location.lat, lng: resultData.location.lng });
          }
        })
        .catch(() => this.clearLocation());
    } else {
      this.clearLocation();
    }
  }

  private getPlaceAutocomplete(search: string, callback: (p: IAddressOption[]) => void) {
    return new Promise((resolve, reject) => {
      getAutocomplete(search, this.sessionId)
        .then((resultData: IAddressOption[]) => {
          resolve(callback(resultData || []));
        })
        .catch(reject);
    });
  }

  private loadOptions = (inputValue: string, callback: (p: any) => void) => {
    inputValue.length
      ? this.getPlaceAutocomplete(inputValue, (res) => {
          callback(res);
        })
      : callback([]);
  };

  render() {
    const { setRef = () => {}, className = '', classNameActive = '' } = this.props;

    const {
      dispensariesList,
      activeDispensary,
      sortingRule,
      filteringRule,
      dispensariesListFetching,
      dispensariesListFetchingFailed,
    } = this.state;

    const classes = _.merge(
      {
        root: '',
        header: '',
        headerLogo: '',
        headerTitle: '',
        headerSearch: '',
      },
      this.props.classes,
    );
    const filteredDispensariesList = dispensariesList.filter(filteringRule).sort(sortingRule);

    return (
      <section className={classnames(styles.dispensaries, className, classes.root)} ref={setRef}>
        <header className={classnames(styles.dispensariesHeader, classes.header)}>
          <svg
            className={classnames(styles.dispensariesHeaderLogo, classes.headerLogo)}
            viewBox="0 0 33 35"
          >
            <g id="Click_logo-mark" transform="translate(0.254)">
              <path
                d="M20.777,27.295a4.816,4.816,0,0,1-2.394-.627l-.16-.078a.477.477,0,0,1-.186-.131l-.106-.078a30.625,30.625,0,0,0-3.857-2.586,27.894,27.894,0,0,0-4.23-1.985l-.133-.052a.8.8,0,0,1-.213-.1l-.133-.078L9.231,21.5a4.792,4.792,0,0,1,0-8.358l.053-.026h0l.133-.078c.053-.026.133-.052.186-.078l.133-.052a30.665,30.665,0,0,0,4.23-1.985,29.233,29.233,0,0,0,3.884-2.612l.133-.1c.053-.052.133-.078.186-.131l.106-.052H18.3a5.041,5.041,0,0,1,2.447-.653,4.866,4.866,0,0,1,4.523,2.978,1.221,1.221,0,0,0,1.7.575c1.463-.81,3.591-1.985,4.895-2.69a1.2,1.2,0,0,0,.372-1.776,17.469,17.469,0,0,0-6.757-4.937h0l-.16-.078A19.139,19.139,0,0,0,17.665,0a17.766,17.766,0,0,0-12.5,4.937A17.153,17.153,0,0,0,0,17.4V17.6A17.153,17.153,0,0,0,5.161,30.063,17.766,17.766,0,0,0,17.665,35a19.139,19.139,0,0,0,7.662-1.437l.16-.078h0a17.862,17.862,0,0,0,6.757-4.937,1.2,1.2,0,0,0-.372-1.776c-1.33-.731-3.538-1.959-5.028-2.769a1.236,1.236,0,0,0-1.7.522A4.706,4.706,0,0,1,20.777,27.295Z"
                fill="#5E91FF"
              />
              <path
                d="M12.185,1.851A1.82,1.82,0,0,0,10.379,0a1.726,1.726,0,0,0-.9.257l-.05.026h0A30.677,30.677,0,0,1,5.39,3.136,30.208,30.208,0,0,1,.953,5.295h0L.9,5.321a1.858,1.858,0,0,0,0,3.187l.125.077h0a30.208,30.208,0,0,1,4.438,2.159A33.245,33.245,0,0,1,9.5,13.571h0a1.78,1.78,0,0,0,2.658-1.594v-.129a31.059,31.059,0,0,1-.376-5.012,31.094,31.094,0,0,1,.4-4.986Z"
                transform="translate(10.154 10.352)"
                fill="#5E91FF"
              />
            </g>
          </svg>

          <h1
            className={classnames(styles.dispensariesHeaderTitle, classes.headerTitle)}
            id="dispensaries"
          >
            LOCATE
          </h1>

          <div className={classnames(styles.dispensariesHeaderSearch, classes.headerSearch)}>
            <div className={styles.dropdown}>
              <AsyncSelect
                value={this.state.selectAddress}
                isClearable={true}
                styles={selectCustomStyle}
                getOptionLabel={(option: IAddressOption) => option.description}
                cacheOptions
                loadOptions={this.debouncedLoadOptions}
                defaultOptions
                placeholder={'Street Address'}
                onChange={(option: any) => {
                  this.onSelectAddress(option);
                }}
              />
            </div>
          </div>
        </header>

        <Tabs>
          <Tab title="Map view" defaultActive>
            <div className={styles.dispensariesBody}>
              <div className={styles.dispensariesList}>
                <DispensariesFilter
                  className={classnames(styles.dispensariesFilter, classes.filterButton)}
                  onChange={this.onChangeFilter}
                />

                <div className={styles.dispensariesRoll}>
                  {filteredDispensariesList.length ? (
                    filteredDispensariesList.map((dispensary: IDispensary) => (
                      <Dispensary
                        key={dispensary.id}
                        dispensary={dispensary}
                        isActive={this.isDispensaryActive(dispensary.id)}
                        onClick={() => this.setActiveDispensary(dispensary)}
                        onRedirect={(name) => this.props.onRedirect && this.props.onRedirect(name)}
                        classNameActive={classNameActive}
                      />
                    ))
                  ) : (
                    <p className={styles.noResults}>No results!</p>
                  )}
                </div>
              </div>

              <div className={styles.dispensariesMap}>
                <GoogleMap
                  dispensariesList={filteredDispensariesList}
                  activeDispensary={activeDispensary}
                  chooseDispensary={this.chooseDispensary}
                />
              </div>
            </div>
          </Tab>
          <Tab title="List view">
            <div className={classnames(styles.dispensariesBody, styles.listView)}>
              <DispensariesFilter
                className={classnames(styles.dispensariesFilter, classes.filterButton)}
                onChange={this.acceptFilter}
              />

              <div className={styles.dispensariesRoll}>
                {filteredDispensariesList.length ? (
                  filteredDispensariesList.map((dispensary: IDispensary) => (
                    <Dispensary
                      isActive
                      variant={Dispensary.variants.withImage}
                      key={dispensary.id}
                      dispensary={dispensary}
                      className={styles.dispensariesRollItem}
                      classNameActive={classNameActive}
                    />
                  ))
                ) : (
                  <p className={styles.noResults}>No results!</p>
                )}
              </div>
            </div>
          </Tab>
        </Tabs>

        <Preloader isActive={dispensariesListFetching} />
        <NotFound
          isActive={dispensariesListFetchingFailed}
          onClose={() => this.closeNotFoundMessage()}
        />
      </section>
    );
  }
}

export default DispensariesMap;
