import React from 'react';
import { withProps, compose } from 'recompose';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

import styles from './Map.module.scss';
import { GoogleMapProps } from './interfaces';

import locationActive from '../../../../images/Icon-Location-List-Active.svg';
import locationInactive from '../../../../images/Icon-Location-List-Inactive.svg';

const mapKey = 'AIzaSyBhVXU1-gv4PBoHXnIRNolvHS9CXmoGAk8';

const mapOptions: google.maps.MapOptions = {
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  styles: [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#85949E' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#000000' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#000000' }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#C5DCF4' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#94A5B7' }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#B9CCE4' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [{ color: '#B9CCE4' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9AABBE' }],
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#716E4C' }],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#716E4C' }],
    },
    {
      featureType: 'road.arterial',
      stylers: [{ color: '#B9CCE3' }],
    },
    {
      featureType: 'road.local',
      stylers: [{ color: '#B9CCE3' }],
    },
  ],
};

const MapWithAMarkerClusterer = compose<GoogleMapProps, GoogleMapProps>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${mapKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div />,
    containerElement: <div className={styles.map} />,
    mapElement: <div style={{ width: '100%', height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ activeDispensary, dispensariesList, chooseDispensary }) => {
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (map && activeDispensary && activeDispensary.lat && activeDispensary.lng) {
      map.panTo({
        lat: parseFloat(activeDispensary.lat),
        lng: parseFloat(activeDispensary.lng),
      });

      map.setZoom(15);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDispensary]);

  return (
    <GoogleMap
      defaultZoom={5}
      defaultCenter={{ lat: 36.778259, lng: -119.417931 }}
      options={mapOptions}
      ref={(ref) => {
        const map: google.maps.Map = ref?.context?.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

        setMap(map);

        if (map && !map.data.getFeatureById('California')) {
          map.data.loadGeoJson('/geojson/california.geojson', { idPropertyName: 'name' });
          map.data.setStyle((feature: google.maps.Data.Feature) =>
            feature.getProperty('name') === 'California'
              ? {
                  fillColor: 'rgba(255,255,255,.5)',
                  strokeWeight: 1,
                  strokeColor: 'rgba(255,0,0,.5)',
                  zIndex: 1,
                }
              : {},
          );
        }
      }}
    >
      <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
        {dispensariesList.map((marker) => (
          <Marker
            key={marker.id}
            icon={{
              url: marker.id === activeDispensary.id ? locationActive : locationInactive,
              scaledSize: new google.maps.Size(40, 50),
            }}
            position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
            onClick={() => chooseDispensary(marker)}
          />
        ))}
      </MarkerClusterer>
    </GoogleMap>
  );
});

export default MapWithAMarkerClusterer;
