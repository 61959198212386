import React from 'react';
import classnames from 'classnames';
import styles from './NotFound.module.scss';

interface INotFoundProps {
  isActive: boolean;
  onClose(): void;
}

function NotFound({ isActive, onClose }: INotFoundProps) {
  React.useEffect(() => {
    if (isActive) {
      document.body.classList.add(styles.notFound);
    } else {
      document.body.classList.remove(styles.notFound);
    }
  }, [isActive]);

  return (
    <section className={classnames(styles.notFoundOverlay, { [styles.active]: isActive })}>
      <header className={styles.notFoundHeader}>
        oops!
        <br />
        CLICK is currently not&nbsp;available
        <br />
        in your area
      </header>
      <div className={styles.notFoundBody}>
        Don’t worry! We're expanding, and hopefully, be available there soon!
      </div>
      <footer className={styles.notFoundFooter}>
        <button type="button" onClick={onClose}>
          Go Back
        </button>
      </footer>
    </section>
  );
}

NotFound.defaultProps = {
  isActive: false,
};

export default NotFound;
