import React from 'react';
import classnames from 'classnames';

import { DispensariesFilterEnum, DispensariesFilterTypesEnum } from '../../../../services/enums';

import style from './DispensariesFilter.module.scss';

interface IDispensariesFilterProps {
  className: string;
  onChange(filterName: DispensariesFilterEnum): void;
}

const dispensariesFilterList = {
  [DispensariesFilterEnum.all_stores]: {
    sort: 0,
    type: DispensariesFilterTypesEnum.filter,
    title: 'All Retailers',
  },
  [DispensariesFilterEnum.proximity]: {
    sort: 1,
    type: DispensariesFilterTypesEnum.sort,
    title: 'Nearest Retailers',
  },
  [DispensariesFilterEnum.southern_california]: {
    sort: 2,
    type: DispensariesFilterTypesEnum.filter,
    title: 'Southern California',
  },
  [DispensariesFilterEnum.northern_california]: {
    sort: 3,
    type: DispensariesFilterTypesEnum.filter,
    title: 'Northern California',
  },
  // [DispensariesFilterEnum.new_stores]: {
  //   sort: 4,
  //   type: DispensariesFilterTypesEnum.sort,
  //   title: 'New stores',
  // },
};

function DispensariesFilter({ className, onChange }: IDispensariesFilterProps) {
  const [isFilterListOpen, toogleFilterList] = React.useState(false);
  const [currentFilterName, changeCurrentFilterName] = React.useState(
    DispensariesFilterEnum.all_stores,
  );

  const filterButtonRef = React.useRef(null);
  const filterListRef = React.useRef(null);

  const changeFilter = (filterName: DispensariesFilterEnum) => {
    changeCurrentFilterName(filterName);
    onChange(filterName);
    toogleFilterList(false);
  };

  const closeFilterListOutside = (event: MouseEvent) => {
    let target = event.target as HTMLElement | null | undefined,
      ultimateTarget = (filterListRef.current as unknown) as HTMLElement,
      buttonTarget = (filterButtonRef.current as unknown) as HTMLElement;

    while (target !== ((document as unknown) as HTMLElement | null | undefined)) {
      if (
        target === buttonTarget ||
        target?.parentElement === buttonTarget ||
        target?.parentElement?.parentElement === buttonTarget
      ) {
        break;
      }

      if (target !== ultimateTarget?.parentElement) {
        toogleFilterList(false);
        break;
      }

      target = target?.parentElement;

      if (!target) {
        break;
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', closeFilterListOutside);

    return () => {
      document.removeEventListener('click', closeFilterListOutside);
    };
  }, []);

  return (
    <div className={classnames(style.dispensariesFilter, className)}>
      <button
        type="button"
        ref={filterButtonRef}
        onClick={() => toogleFilterList(!isFilterListOpen)}
        className={classnames(style.dispensariesFilterButton, {
          [style.dispensariesFilterButtonActive]: isFilterListOpen,
        })}
      >
        {dispensariesFilterList[currentFilterName]?.title || ''}
        <svg
          width="19"
          height="10"
          viewBox="0 0 19 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={style.dispensariesFilterButtonIcon}
        >
          <path
            d="M1.47888 0C0.579022 0 0.137017 1.09567 0.784986 1.72008L8.68347 9.33134C9.07078 9.70457 9.68394 9.70457 10.0713 9.33134L17.9697 1.72008C18.6177 1.09567 18.1757 0 17.2758 0H1.47888Z"
            fill="#626262"
          />
        </svg>
      </button>
      <ul
        ref={filterListRef}
        className={classnames(style.dispensariesFilterList, {
          [style.dispensariesFilterListActive]: isFilterListOpen,
        })}
      >
        {Object.entries(dispensariesFilterList)
          .sort(([_, filterElement]) => filterElement.sort)
          .map(([filterElementName, filterElement]) => (
            <li
              key={filterElementName}
              className={style.dispensariesFilterItem}
              onClick={() => changeFilter(filterElementName as DispensariesFilterEnum)}
            >
              {filterElement.title}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default DispensariesFilter;
