import React from 'react';
import classnames from 'classnames';

import { IDispensary } from './DispensariesMap';

import emptyImage from 'images/empty-image.png';

import styles from './Dispensary.module.scss';

enum dispensaryVariants {
  default = 'default',
  withImage = 'withImage',
}

interface IDispensaryProps {
  dispensary: IDispensary;
  isActive: boolean;
  onClick?(): any;
  onRedirect?(name: string): any;
  variant?: dispensaryVariants;
  className?: string;
  classNameActive?: string;
}

const Dispensary = (props: IDispensaryProps) => {
  const {
    dispensary,
    onClick = () => null,
    className = '',
    variant = dispensaryVariants.default,
    onRedirect,
    isActive,
    classNameActive,
  } = props;

  const dispensaryBase = (
    <div
      className={classnames(
        styles.dispensaryBox,
        {
          [className]: variant === dispensaryVariants.default,
        },
        {
          [classNameActive || styles.active]: isActive,
        },
      )}
      ref={dispensary.ref}
      onClick={onClick}
    >
      <div className={styles.nameAddressBox}>
        <span className={styles.name}>{dispensary.name}</span>
        <div className={styles.divider} />
        <span className={styles.address}>
          {`${dispensary.address}, ${dispensary.city}, ${dispensary.state} ${dispensary.zip}`}
        </span>
      </div>
      <a
        href={'https://' + dispensary.website}
        target="_blank"
        rel="noreferrer"
        onClick={() => onRedirect && onRedirect(dispensary.name)}
        className={styles.button}
      >
        Visit Website{' '}
        <svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.84521 0.125L6.62646 0.375C6.56396 0.4375 6.53271 0.520833 6.53271 0.625C6.53271 0.729167 6.56396 0.822917 6.62646 0.90625L12.2202 6.46875H0.470215C0.366048 6.46875 0.272298 6.51042 0.188965 6.59375C0.126465 6.65625 0.0952148 6.73958 0.0952148 6.84375V7.15625C0.0952148 7.26042 0.126465 7.35417 0.188965 7.4375C0.272298 7.5 0.366048 7.53125 0.470215 7.53125H12.2202L6.62646 13.0938C6.56396 13.1771 6.53271 13.2708 6.53271 13.375C6.53271 13.4792 6.56396 13.5625 6.62646 13.625L6.84521 13.875C6.92855 13.9375 7.0223 13.9688 7.12646 13.9688C7.23063 13.9688 7.31396 13.9375 7.37646 13.875L13.9702 7.25C14.0535 7.1875 14.0952 7.10417 14.0952 7C14.0952 6.89583 14.0535 6.8125 13.9702 6.75L7.37646 0.125C7.31396 0.0625 7.23063 0.03125 7.12646 0.03125C7.0223 0.03125 6.92855 0.0625 6.84521 0.125Z"
            fill="#5E91FF"
          />
        </svg>
      </a>
    </div>
  );

  switch (variant) {
    case dispensaryVariants.withImage:
      return (
        <div className={classnames(styles.dispensaryWithImage, className)}>
          <div className={classnames(styles.dispensaryWithImage, styles.image)}>
            {dispensary.logoUrl ? (
              <img alt={dispensary.name} src={dispensary.logoUrl} />
            ) : (
              <div
                className={styles.imageEmpty}
                style={{ background: `url(${emptyImage}) center center no-repeat` }}
              />
            )}
          </div>
          {dispensaryBase}
        </div>
      );

    default:
      return dispensaryBase;
  }
};

Dispensary.variants = dispensaryVariants;

export default Dispensary;
