import _ from 'lodash';
import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import styles from './Tabs.module.scss';

interface ITabsProps {
  children: JSX.Element[];
}

function Tabs({ children }: ITabsProps) {
  const [tabs, setTabs] = useState(_.flatten([children]));
  const [activeTabIndex, changeActiveTabIndex] = useState(
    ((index) => (index < 0 ? 0 : index))(
      _.flatten([children]).findIndex((tab) => tab.props.defaultActive),
    ),
  );

  useEffect(() => {
    setTabs(_.flatten([children]));
  }, [children]);

  return (
    <section className={styles.tabs}>
      <header className={styles.tabsHeader}>
        <ul className={styles.tabsHeaderList}>
          {tabs.map((tab, index) => (
            <li
              key={index}
              onClick={() => changeActiveTabIndex(index)}
              className={classnames(styles.tabsHeaderListItem, {
                [styles.tabsHeaderListItemActive]: index === activeTabIndex,
              })}
            >
              {tab.props.title}
            </li>
          ))}
        </ul>
      </header>
      {tabs[activeTabIndex]}
    </section>
  );
}

export default Tabs;
